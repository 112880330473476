import React from 'react'

import { Container, TextSide, TitleTax, SubTax, DescTax, ImageSide } from './styles' 

import WowTaxes from '../../images/wowPay/taxImg.svg'

function WowpayTax(props){

   return(

      <Container>
         <TextSide>
         
            <TitleTax>
               {props.taxTitle}
            </TitleTax>
            <SubTax>
               {props.taxSub}
            </SubTax>
            <DescTax>
               {props.taxDesc}
            </DescTax>

         </TextSide>
         <ImageSide>
            <img src={WowTaxes} alt='Taxes' />
         </ImageSide>
      </Container>

   )

}

export default WowpayTax