import styled from 'styled-components'

export const Bandeiras = styled.div`

    display: flex;
    flex-direction: column;
    margin-top: 100px;

    & > .titulo-bandeiras{
        color: #08C1C1;
        margin-bottom: 10px;
        font-size: 18px;
    }

    & > .img-bandeiras{
        display: flex;
        flex-direction: row;

        & > img{
        height: 30px;
        margin-right: 20px;
        }
    }

    @media (min-width : 834px) and (max-width : 1366px){

        width:min-content !important;

        & > .img-bandeiras > img{
        height: 14px;
        }
    }

    @media (min-width : 768px) and (max-width : 833px){

         & > .img-bandeiras > img{
            height: 10px;
        }
    }

    @media (min-width : 320px) and (max-width : 414px){
        & > .img-bandeiras > img{
        height: 15px;
        margin-right: 10px; 
        }  
    }



`