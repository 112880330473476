import styled from 'styled-components'

export const Container = styled.div`

   display: flex;
   flex-direction:column;
   justify-content: space-between;

`

export const InfoDiv = styled.div`

   height: 540px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 150px;
   background: white;

   @media (min-width:834px) and (max-width: 1440px){
      height: 400px;
   }

   @media (min-width: 768px) and (max-width: 833px){
      height: 300px;
   }

   @media (min-width: 320px) and (max-width: 414px){
      text-align: center;
   }


`

export const InfoTitle = styled.div`

   text-transform: uppercase;
   color: #08C1C1;
   font-size: 48px;
   margin-bottom: 16px;

`

export const InfoSub = styled.div`

   font-size: 24px;
   color: #1E3760;
   margin-bottom: 16px;

`

export const InfoDesc = styled.div`

   font-size: 18px;
   color: #1E3760;
   max-width: 600px;
   text-align: center;
   margin-bottom: 16px;

   @media (min-width: 320px) and (max-width: 414px){
      max-width: 300px
   }

`

export const Imgs = styled.div`

   height: 540px;
   background: linear-gradient(180deg, #00879A 0%, #00BFBF 100%);
   display: flex;
   align-items: center;
   position: relative;

   @media (min-width:834px) and (max-width: 1440px){
      height: 400px;
   }
   
   @media (min-width: 414px) and (max-width: 833px){
      height: 300px;
   }

   @media (min-width: 321px) and (max-width: 413px){
      height: 250px;
   }

   @media (max-width: 320px){
      height: 200px;
   }


`

export const WowBlock = styled.img`

   margin-left: 100px;
   margin-right: 200px;

   @media (min-width: 834px) and (max-width: 1440px){
      height: 80px;
   }

   @media (min-width: 834px) and (max-width: 1199px){
      margin-left: 50px;
   }

   @media (min-width:  415px) and (max-width: 768px){
      height: 70px;
      margin-left: 50px;
   } 

   @media (min-width: 320px) and (max-width: 414px){
      display: none;
   }

`

export const WowPos = styled.img`

   position: absolute;
   right: 22%;
   top: -10%;

   @media (min-width:1367px) and (max-width: 1440px){
      height: 439px;
   }

   @media (min-width:1024px) and (max-width: 1366px){
      height: 439px;
   }

   @media (min-width: 1024px) and (max-width: 1199px){
      right: 10%;
   } 
  
   @media (min-width: 834px) and (max-width: 1023px){
      height: 439px;
      right: 10%;
   }

   @media (min-width: 415px) and (max-width: 768px){
      height: 330px;
      right: 13%;
   }

   @media (min-width: 413px) and (max-width: 414px){
      right: 3%;
      height: 330px;
   }

   @media (min-width: 375px) and (max-width: 412px){
      right: 10%;
      height: 275px;
   }

   @media (min-width: 321px) and (max-width: 374px){
      right: 5%;
      height: 275px;
   }

   @media (max-width: 320px){
      height: 220px;
      right: 10%;
   }

`