import React, { useState } from 'react'
import { Bandeiras } from './styles' 
import Header from '../../components/header'
import SliderBan from '../../components/sliderBan'
import Advantages from '../../components/advantages'
import Definition from '../../components/definition'
import FAQ from '../../components/faq'
import Pay from '../../images/wowPay/Pay.svg'
import Graphics from '../../components/graphics'
import Benefits from '../../components/benefits'
import PosInfo from '../../components/posInfo'
import PayTaxes from '../../components/wowpayTax'
import BannerFoot from '../../components/bannerFoot'
import Footer from '../../components/footer'

import WowPayBanner from '../../images/wowPay/WowPayBanner.svg'
import WowPayBanner2 from '../../images/wowPay/WowPayBanner2.svg'
import FirstLeft from '../../images/wowPay/FirstLeft.svg'
import SecondLeft from '../../images/wowPay/SecondLeft.svg'
import ThirdLeft from '../../images/wowPay/ThirdLeft.svg'
import FourthLeft from '../../images/wowPay/FourthLeft.svg'
import FirstRight from '../../images/wowPay/FirstRight.svg'
import SecondRight from '../../images/wowPay/SecondRight.svg'
import ThirdRight from '../../images/wowPay/ThirdRight.svg'
import FourthRight from '../../images/wowPay/FourthRight.svg'
import GraphicsImg from '../../images/wowPay/Graphics.svg'
import LeftCardImg from '../../images/wowPay/LeftCardImg.svg'
import CenterCardImg from '../../images/wowPay/CenterCardImg.svg'
import RightCardImg from '../../images/wowPay/RightCardImg.svg'

import BandeirasCard from '../../images/imagesBandeiras/bandeiras.svg'

import ResponsiveHead from '../../components/ResponsiveHead'
import { useStaticQuery, graphql } from 'gatsby'

export default () =>{

const [open, setOpen] = useState(false);

   const data = useStaticQuery(graphql`
   {
      site {
      siteMetadata {
         WowPay{
               text
         }
      }
      }
   }`)

   const slidesInfo = [
		{
			image: WowPayBanner,
			titleHead: 'Wow pay',
         descriptionHead: 'Vendas, presenciais ou à distância, gestão de fluxo, simulação de preços! Muito mais do que uma maquininha!',
         btnText: 'Eu quero!',
         urlBtn: 'https://www.wowmanager.com.br/signup'
		},
		{
			image: WowPayBanner2,
			titleHead: 'Venda Online',
         descriptionHead: 'Tenha mais opções na hora de vender à distância com a Wow Pay ',
         btnText: 'Eu quero!',
         urlBtn: 'https://www.wowmanager.com.br/signup'
		}

	]

   const leftItems = [
      {
         text: "Receba por suas vendas em 24hrs. Dinheiro na conta e controle na mão!",
         image :{
               src: FirstLeft,
               alt: "First Left"
         }

      },
      {
         text: "Use a plataforma Wow para ter acesso a todas informações, relatórios, comprovantes, agendas de recebimento e muito mais!",
         image :{
               src: SecondLeft,
               alt: "Second Left"
         }

      },
      {
         text: "Principais bandeiras de crédito e débito são aceitas. Muito mais comodidade para seu cliente e satisfação para você!",
         image :{
               src: ThirdLeft,
               alt: "Third Left"
         }

      },
      {
         text: "Use os gráficos da Wow para ter toda a gestão do seu negócio, sem complicar e sem demorar!",
         image :{
               src: FourthLeft,
               alt: "Fourth Left"
         }

      }
   ]

   const rightItems = [
      {
         text: "Encontre as melhores datas, períodos, momentos do seu negócio! Reconheça cada detalhe e use os insights da Wow para saber quando é hora de investir e quando é hora de esperar.",
         image :{
               src: FirstRight,
               alt: "First Right"
         }

      },
      {
         text: "Utilize nossos filtros inteligentes para entender melhor como seus clientes consomem e analisar o comportamento de suas vendas.",
         image :{
               src: SecondRight,
               alt: "Second Right"
         }

      },
      {
         text: "Use as Lista de Favorecidos e a Listagem de Últimas Transferências para realizar pagamentos para seus funcionários, suas contas e seus fornecedores sem perder tempo.",
         image :{
               src: ThirdRight,
               alt: "Third Right"
         }

      },
      {
         text: "Instale aplicações diretamente em sua máquina de cartão, como calculadora, taxa ao portador e relatórios.",
         image :{
               src: FourthRight,
               alt: "Fourth Right"
         }

      }
   ]

   const definitionTitle = "Wow Pay é..."

   const definitionSubTitle = "Muito mais do que uma máquina de cartão"

   const definitionText = `Sua empresa prosperando com nossa maquininha, com tecnologia, informação, rapidez e de forma descomplicada. 
   Tudo para seu negócio crescer de forma estruturada e segura. Tenha em suas mãos tudo sobre suas vendas, presenciais ou não, 
   insights sobre sua gestão de fluxo, simulação de preços e muitas outras vantagens que vão mudar seu jeito de valorizar seu dinheiro!`

   const graphicsTitle = [ "O melhor para vocÊ e seu negócio" ]

   const graphicSubTitle = [ "Unifique. Automatize. Surpreenda-se. Aproveite." ]

   const graphicsText = [ "Comparativo de entradas, saídas, formas de recebimento e melhores dias. Possibilidade de criar filtros comparativos entre períodos. Gráficos e extratos por período (PDF e XLS Excel). Movimentações na linha do tempo. Diferentes tipos de cobrança. Pagamentos à distância com Venda Digitada ou Link de Cobrança. Atualização em tempo real. Gestão de diversas contas na mesma plataforma." ]

   const posTitle = [ "mult ec" ]

   const posSub = [ "Uma máquina, múltiplos estabelecimentos" ]

   const posDesc = [ "Com a Wow Pay você pode direcionar o valor total da transação para cada usuário credenciado. Ideal para salões de beleza, clínicas médicas ou estabelecimentos que atuam vários profissionais.Com maior transparência e controle dos recebíveis, você poderá acessar sua conta digital a qualquer momento e conferir todos os seus ganhos." ]
   
   const taxTitle = ["taxa ao portador"]

   const taxSub = ["Para você que antecipa seus recebíveis."]

   const taxDesc = ["Uma solução que te ajuda a calcular as taxas de antecipação, de acordo com as parcelas que seu cliente deseja. Mais comodidade para ele, sem interferir nos recebimentos de seu negócio."]

   const cards = [
      {
         title:'Gráficos',
         subTitle:'Aquela ajuda na hora de tomar decisões',
         text:'Acompanhe suas vendas, seu ticket médio, separe por período, por bandeiras ou até mesmo por tipo de transações. Compare suas vendas do dia com as vendas anteriores, tenha um balanço detalhado de seus recebíveis.',
         img: LeftCardImg
      },
      {
         title: 'Calculadora',
         subTitle:'Calcule direto em sua máquina de cartão',
         text:'Com o objetivo de auxiliar você no momento de efetuar uma venda o aplicativo calculadora realiza operações matemáticas e ajuda a dividir a conta entre várias pessoas.',
         img: CenterCardImg
      },
      {
         title: 'Cobranças',
         subTitle:'Venda mais, venda a distância.',
         text:'Faça suas vendas a distância com as melhores opções para você e seu cliente: Gere boleto, faça venda digitadas de cartões de créditos, gere link de cobrança, no qual o  próprio cliente pode efetuar o pagamento em um ambiente seguro. Crie cobranças do dia ou em ciclos de recorrentes como semanais, mensais e etc.',
         img: RightCardImg
      }
   ]

   const faqItems = [
      {
         title: "O que é a Wow Pay?",
         text: "A Wow Pay é uma solução Wow Solution que une a famosa maquininha de cartão com uma plataforma de gestão financeira. Juntas, elas oferecem informações e análises valiosas sobre seu negócio, seus clientes, seu fluxo e muito mais! "
      },
      {
         title: "Eu posso receber minhas vendas na conta Wow Manager?",
         text: "Sim! Você pode optar pela conta Wow para receber seu reembolso e aproveitará todas as vantagens que a Wow oferece! Sabe qual a melhor parte? Quanto mais você usa, mas precisos são os alertas e os insights que a Wow entrega. E você ainda pode transferir seu saldo para seus cartões de crédito pré-pagos da Wow na hora!"
      },
      {
         title: "O que a maquininha da Wow oferece?",
         text: "Além de toda plataforma de gestão financeira, a maquininha da Wow traz as novidades do mercado. Consiga, de forma simples, receber suas vendas de débito e crédito em 24hrs, fazer a divisão dos pagantes na própria máquina com nossa calculadora integrada (ótima opção para bares e restaurantes), uso da opção de Taxa Ao Portador, e, se quiser, pode utilizar o Split, que é a divisão dos recebíveis – uma ótima opção para quem atua com fornecedores ou comissão. E isso é só começo!"
      }
   ]

   return(
      <>
         <Header open={open} setOpen={setOpen} currentPage='Wow Pay' />
         <ResponsiveHead open={open} setOpen={setOpen} />
			<SliderBan slides={slidesInfo} />
         <Benefits benefitsCards={cards} />
         <Definition title={definitionTitle} subTitle={definitionSubTitle} text={definitionText} img={Pay} justifyContent={'flex-end'} >
            <Bandeiras>
               <div className='titulo-bandeiras'>
                  <span><b>Bandeiras aceitas</b></span>
               </div>
               <div className='img-bandeiras'>
                  <img src={BandeirasCard} alt='Bandeiras' />  
               </div>
            </Bandeiras>
         </Definition>
         <Graphics title={graphicsTitle} subTitle={graphicSubTitle} description={graphicsText} img={GraphicsImg} hImg={300} />
         <PosInfo posTitle={posTitle} posSub={posSub} posDesc={posDesc}  />
         <Advantages leftItems={leftItems} rightItems={rightItems} />
         <PayTaxes taxTitle={taxTitle} taxSub={taxSub} taxDesc={taxDesc} />
         <FAQ items={faqItems}/>

         <BannerFoot>{data.site.siteMetadata.WowPay.text}</BannerFoot>

         <Footer />
      </>
   )
}