import styled from 'styled-components'

export const Container = styled.div` 

   height: 1080px;
   display: flex;
   flex-direction: row;

   @media (min-width: 1025px) and (max-width: 1440px){
      height: 950px;
   }

   @media (min-width: 320px) and (max-width: 1024px){
      flex-direction: column;
      height: auto;
   }


`

export const TextSide = styled.div`

   display:flex;
   flex-direction: column;
   align-items: center;
   background: white;
   width: 50%;
   justify-content: center;
   text-align: right;

   @media (min-width: 320px) and (max-width: 1024px){
      width: auto;
      height: 500px;
   }

`

export const TitleTax = styled.div`

   font-size: 48px;
   color: #08C1C1;
   width: 512px;
   padding-bottom: 16px;
   text-transform: uppercase;
   font-weight: 500;

   @media (min-width: 320px) and (max-width: 414px){
      width: 300px;
   }

`

export const SubTax = styled.div`

   font-size: 24px;
   color: #1E3760;
   width: 512px;
   padding-bottom: 16px;

   @media (min-width: 320px) and (max-width: 414px){
      width: 300px;
   }

`

export const DescTax = styled.div`

   font-size: 18px;
   color: #1E3760;
   width: 512px;

   @media (min-width: 320px) and (max-width: 414px){
      width: 300px;
   }

`

export const ImageSide = styled.div`

   display: flex;
   background: linear-gradient(180deg, #00879A 0%, #00BFBF 100%);
   width: 50%;
   justify-content: center;

   & > img{
      align-self: center;

      @media (min-width: 375px) and (max-width: 414px){
         height: 200px;
      }

      @media (min-width: 320px) and (max-width: 374px){
         height: 185px;
      }

   }

   @media (min-width: 320px) and (max-width: 1024px){
      width: auto;
      height: auto;
   }


`