import React from 'react'

import { Container, InfoDiv, InfoTitle, InfoSub, InfoDesc, Imgs, WowPos, WowBlock } from './styles'

import WowLightBlue from '../../images/wowLightBlueBlock.svg'
import WowPosImg from '../../images/wowPay/InfoPosImg.svg'


function PosInfo(props) {

   return(
      <>
         <Container>

            <InfoDiv>
               <InfoTitle>
                  {props.posTitle}
               </InfoTitle>
               <InfoSub>
                  {props.posSub}
               </InfoSub>
               <InfoDesc>
                  {props.posDesc}
               </InfoDesc>
            </InfoDiv>
            
            <Imgs>
               <WowBlock src={WowLightBlue} alt='wow-block'  />
               <WowPos src={WowPosImg} alt='POS' />
            </Imgs>

         </Container>
      </>
   )

}

export default PosInfo